<template>
    <v-layout wrap>
        <v-flex xs12 class="d-flex justify-space-between">
            <span class="display-1 px-5 pb-5 font-weight-medium">{{
                $route.meta.name
            }}</span>
            <v-btn
                v-if="$auth.check() && hasPermission"
                color="secondary"
                @click="add"
                >Adaugă Consilier</v-btn
            >
        </v-flex>
        <v-flex xs12 ml-5>
            <v-layout
                v-for="councilor in data"
                :key="councilor.name"
                align-center
                class="white-bkg-color pa-5 elevation-2 my-4"
            >
                <v-flex class="flex-grow-0 mr-10">
                    <img
                        style="border-radius: 100%"
                        width="200px"
                        height="200px"
                        :src="
                            councilor.src
                                ? councilor.src
                                : `${require('@/assets/person_placeholder.png')}`
                        "
                        alt=""
                    />
                </v-flex>
                <v-flex>
                    <v-layout column align-start>
                        <v-layout class="mt-2">
                            <v-flex class="mr-5" flex-md-row flex-column>
                                <p class="ma-0 subtitle-1">Nume</p>
                                <p class="ma-0 font-weight-bold">
                                    {{ councilor.name }},
                                    <span class="font-italic">{{
                                        councilor.title
                                    }}</span>
                                </p>
                            </v-flex>
                            <v-flex class="ml-5" v-if="councilor.party">
                                <p class="ma-0 subtitle-1">Partid</p>
                                <p class="ma-0 font-weight-bold">
                                    {{ councilor.party }}
                                </p>
                            </v-flex>
                        </v-layout>
                        <v-layout class="mt-2" flex-md-row flex-column>
                            <v-flex class="mr-5">
                                <p class="ma-0 subtitle-1">Telefon</p>
                                <p class="ma-0 font-weight-bold">
                                    {{ councilor.phone }}
                                </p>
                            </v-flex>
                            <v-flex class="ml-5">
                                <p class="ma-0 subtitle-1">Email</p>
                                <p class="ma-0 font-weight-bold">
                                    {{ councilor.email }}
                                </p>
                            </v-flex>
                        </v-layout>
                    </v-layout>
                </v-flex>

                <v-flex class="text-end">
                    <v-btn
                        @click="open(councilor)"
                        x-small
                        tile
                        color="secondary"
                    >
                        <v-icon small class="mr-2">mdi-message</v-icon>
                        Trimite Mesaj
                    </v-btn>
                    <v-btn
                        v-if="$auth.check() && hasPermission"
                        class="ml-2"
                        @click="edit(councilor)"
                        x-small
                        tile
                        color="success"
                        >Editează</v-btn
                    >
                    <v-btn
                        v-if="$auth.check() && hasPermission"
                        class="ml-2"
                        @click="destroy(councilor)"
                        color="error"
                        x-small
                        tile
                        >Șterge</v-btn
                    >
                </v-flex>
            </v-layout>
        </v-flex>
        <v-dialog v-model="dialog" width="700px">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-title v-if="message" primary-title class="headline"
                    >Trimite mesaj consilierului
                    {{ message.to.name }}</v-card-title
                >
                <v-card-text>
                    <v-textarea
                        v-if="message"
                        :error-messages="errors.text"
                        @input="delete errors.text"
                        v-model="message.text"
                        label="Mesajul"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        @click="send"
                        color="secondary"
                        block
                        >Trimite</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import displayErrors from "@/mixins/displayErrors";
import hasPermission from "../mixins/hasPermission";

export default {
    name: "LocalCouncil",
    metaInfo: {
        title: "Consiliul Local -"
    },
    mixins: [displayErrors, hasPermission],
    data: () => ({
        data: [],
        dialog: false,
        item: {},
        message: null,
        loading: false
    }),
    methods: {
        edit(item) {
            this.$router.push({
                name: "edit-counselor",
                query: {
                    id: item.id
                }
            });
        },
        async destroy(item) {
            const res = await this.$confirm({
                message: "Sunteți sigur(ă) că vreți să ștergeți consilierul?"
            });
            if (res) {
                try {
                    await this.axios.post(`/councilors/${item.id}`, {
                        _method: "DELETE"
                    });
                    const index = this.data.indexOf(item);
                    this.data.splice(index, 1);
                } catch (e) {
                    this.loading = false;
                }
            }
        },
        add() {
            this.$router.push({
                name: "add-counselor"
            });
        },
        async getData() {
            const response = await this.axios.get("/councilors");
            this.data = response.data;
        },
        open(councilor) {
            this.message = {};
            this.message.to = councilor;
            this.dialog = true;
        },
        close() {
            this.dialog = false;
            this.message = null;
            delete this.errors.text;
        },
        async send() {
            this.loading = true;
            try {
                await this.axios.post("/councilors/message", this.message);
                this.close();
            } catch (e) {
                this.loading = false;
            }
            this.loading = false;
        }
    },
    watch: {
        dialog() {
            return this.dialog || this.close();
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style scoped lang="scss">
.white-bkg-color {
    background-color: white;
}
</style>
